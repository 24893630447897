import React from 'react'
import { Box } from 'theme-ui'

const styles = {
  wrapper: {
    position: `relative`
  }
}

export const Hero = ({ children, sx, wide, full, ...props }) => (
  <Box sx={{ ...styles.wrapper, ...sx }} {...props}></Box>
)
